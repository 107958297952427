@import "src/common/style/config";
$padding: 15px;

.container {
  padding: $padding;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.list {
  &.container {
    flex-direction: row-reverse;
  }
}

.select {
  min-width: 180px;
  :global .ant-select-clear {
    right: 30px;
  }
}

.icon {
  cursor: pointer;
  width: 32px;
  height: 32px;

  &:hover * {
    fill: $greenMiddle;
  }
}

.iconActive * {
  fill: $main-color !important;
}