@import 'src/common/style/config';

.box {
  background: $white-dark;
  padding: 20px;
  margin-bottom: 2px;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.edit {
  border: 1px solid $secondary-color;
  padding: 0;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 10px;
}

.listItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
  font-size: 14px;
  margin-right: 10px;
}

.deleteAccount {
  padding: 0!important;
  border: none!important;
  margin-bottom: 30px!important;
  margin-top: 50px;
  
  & > span {
    text-decoration: underline!important;  
  }
  
  &:hover,
  &:active,
  &:focus {
    background: transparent;
  }
  
}

.become {
  margin-bottom: 40px;
}

.judgeBtn {
  padding: 15px 20px!important;
  margin: 5px 0;
  
  &:hover .judgeBtnTxt {
    color:#fff
  }
}

.judgeBtnTxt {
  color: $accent-color;
  display: block;
  margin-left: 20px;
}