@import 'src/common/style/config';
.container {
  margin-bottom: 24px;
}

.title {
  font-weight: 400;
  font-size: 16px;
  display: block;
  margin-right: 15px;
}

.description {
  font-weight: 300;
  font-size: 14px;
  display: block;
  color: #3F3F3F;
  margin-bottom: 8px;
  margin-top: -8px;
}

.required {
  &:after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.row {
  width: 100%;
}

.line {
  width: 80%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
}

.lineInput {
  flex: 1;

  :global .ant-form-item-control {
    width: 100%;
    max-width: 100%;
  }
}

.addButton {
  border-radius: 0;
  margin-top: -10px !important;
  display: block;
}

.removeButton {
  width: 42px;
  height: 42px;
  margin-left: 10px;
}