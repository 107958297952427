@import 'src/common/style/_config.scss';

$content-padding: 70px;

.container {
    background-color: $secondary-color;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
}

.content {
    max-width: 950px;
    width: 100%;
    background-color: $white;
    padding: 50px $content-padding;
    position: relative;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2;
}

.title {
    font-weight: 300 !important;
    margin-bottom: 10px !important;
}

.help {
    display: block;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 40px;
}

.message {
    padding-top: 30px;
    font-size: 16px;
    display: flex;
}

.errorIcon {
    color: $red;
    font-size: 24px;
    margin-right: 10px;
}

.messageError {
    color: $red;
}

.link {
    padding: 0px 5px;
}

.backButton {
    padding: 0;
    border-radius: 0 !important;

    & > span {
        border-bottom: 1px solid $main-color;
        display: inline;
    }
}

.backBtnArrow {
    height: 15px;
    margin-bottom: -1px;
}
