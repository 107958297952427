@import "src/common/style/_config.scss";

$login-padding: 50px;

.container {
  background-color: $secondary-color;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
}

.content {
  width: 550px;
  background-color: $white;
}

.box {
  padding: $login-padding $login-padding 90px $login-padding;
  height: 100%;
  position: relative;
}

.bg {
  background-color: #E9E9E9;
}

.title {
  font-weight: 300!important;
  margin-bottom: 40px !important;
}
.btn {
  min-width: 160px;
}

.button {
  margin:  0 0 30px 0;
  background: $secondary-color;
  border-color: $secondary-color;

  &:last-child {
    margin: 0;
  }
}

.messageBox {
  min-height: 50px;
}

.errorMsg {
  color: $error;
  font-size: 16px !important;
}

/*
.icon {
  width: 18px;
  height: 16px;
  margin-right: 5px;
}*/

/*.center {
  text-align: center;
}*/


/*
.facebook:hover {
  background-color: #4267B2 !important;
  border-color: #4267B2 !important;
}

.twitter:hover {
  background-color: #1DA1F2 !important;
  border-color: #1DA1F2 !important;
}

.google:hover {
  background-color: #DB4437 !important;
  border-color: #DB4437 !important;
}*/

