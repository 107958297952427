@import "src/common/style/config";

.container {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconBtn {
  padding: 8px;
  border-radius: 50%;
  background: $gry-light-2;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  
  &:hover,
  &:active,
  &:focus {
    background: $greenLight;
    .icon * {
      fill: $main-color;
    }
  }
}

.iconBtnSelected {
  background: $greenLight;
  .icon * {
    fill: $main-color;
  }
}

.icon {
  width: 20px;
  height: 20px;
  
  & * {
    fill: $grey-light;
  }
}