@import 'src/common/style/config';
@import "src/common/style/_mixins.scss";

.container {
    padding: 20px 0;
}

.info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    margin-bottom: 25px;
    margin-right: 10px;
}

.infoTitle {
    font-size: 22px !important;
    margin-bottom: 0 !important;
}

.infoText {
    font-size: 18px;
    font-weight: 300;
}

.content {
    width: 90%;
    max-width: 1000px;
}

.answers {
    background: $grey-super-light;
    padding: 40px 60px;
    width: 100%;
}

.description {
    white-space: pre-wrap;
}

.payBtn {
    margin-left: 20px;
}

.buttonLeft {
    justify-content: flex-start;
}

.header {
    max-width: 1000px;
}

.goToSubmissions {
    margin-right: 10px;
}

.footer {
    @include submissionFooter;
}

.footerContent {
    @include submissionFooterContent;
}