@import 'src/common/style/config';

.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    min-height: 100px;
    max-width: 1000px;
}

.back {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: auto;
}

.saveDraft {
    margin-right: 20px;
}
