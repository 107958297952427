.contentLoader {
  border: 1px solid #ccc;
  background: #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 950px;
  min-height: 300px;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1px solid #ccc;
}

.pdfViewer {
  border: 1px solid #ccc;
  background: #ddd;
}

.PdfPage {
  position: relative;
}

.PdfPage__textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;
}

.PdfPage__textLayer > span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.Loader__container__load {
  margin-top: 1em;
  color: white;
  width: 100%;
  min-height: 300px;
  max-height: 540px;
  overflow: overlay;
}