﻿@mixin submissionTitle {
  background: #F5F5F5;
  width: 100%;
  
  font-size: 36px;
  font-weight: 300;
  line-height: 46px;
  
  & > * {
    display: block;
    width: 100%;
    max-width: calc(#{$max-width} + #{$page-padding});
    padding: 30px $page-padding;
    margin: 0 auto;
  }
}

@mixin pageWrapper {
  width: 100%;
  max-width: calc(#{$max-width} + #{$page-padding});
  padding: 0 $page-padding;
  margin: 0 auto;
}

@mixin submissionFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid $white-grey;
  background: $white;
}

@mixin submissionFooterContent {
  @include pageWrapper;
  min-height: 75px;
}