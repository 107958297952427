@import 'src/common/style/_config.scss';

.arrow {
  width: 48px;
  height: 20px;
  position: relative;
  color: $gry-light-2;
  display: block;
}

.line {
  background-color: currentColor;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.point {
  background-color: currentColor;
  width: 10px;
  height: 1px;
  position: absolute;
}

.leftUpper {
  top: 6px;
  left: 0;
  transform: rotate(-45deg);
}

.leftLower {
  bottom: 5px;
  left: 0;
  transform: rotate(45deg);
}

.rightUpper {
  top: 6px;
  right: 0;
  transform: rotate(45deg);
}

.rightLower {
  bottom: 5px;
  right: 0;
  transform: rotate(-45deg);
}