@import 'src/common/style/config';

.radioGroup {
  display: flex;
  flex-direction: column;
}

.radio {
  align-items: center;
  margin-bottom: 20px;
}

.radioName {
  font-size: 16px;
  display: inline-block;
  line-height: 1.5;
  vertical-align: bottom;
  margin-left: 5px;
  font-weight: 500;
}

.radioPrice {
  font-size: 16px;
  font-weight: 400;
}

.radioDescription {
  font-size: 12px;
  font-weight: 300;
}

.help {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin: -5px 0 10px 0;
}

.link {
  font-size: 14px;
}