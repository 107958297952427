@import 'config';

// Form Label
.ant-form-item-label > label {
    font-family: Graphik;
    font-weight: 400;
    padding-bottom: 6px;
    display: inline-block;
}
.ant-form-item-explain {
    text-align: right;
    font-family: Graphik;
    font-weight: 300;
    font-size: 14px;
    min-height: 24px;
    margin: 0;
}
.ant-form-item-label > label.ant-form-item-required::before {
    display: none !important;
}
.ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.ant-typography {
    font-size: 18px;
    font-weight: 400;
}

// Form input
.ant-input,
.ant-input-affix-wrapper,
.ant-picker-input > input,
.ant-select {
    font-size: 16px;
    font-family: Graphik;
    font-weight: 300;
    border-radius: 0;
}
.ant-input {
    height: 42px;
}
.ant-input-affix-wrapper .ant-input {
    height: 38px;
    margin-top: 2px;
}
.ant-input-group-addon {
    border-radius: 0;
    left: 0 !important;
}

//Form password
.ant-input-password {
    padding-top: 0;
    padding-bottom: 0;
}

//Form picker
.ant-picker,
.ant-picker-input > input {
    border-radius: 0 !important;
}

//Form Select
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0 !important;
}

//Form Multiselect
.ant-select-multiple .ant-select-selector {
    border-radius: 0 !important;
}

//Form Upload
.ant-form-item .ant-upload.ant-upload-drag {
    border-radius: 0 !important;
    background: #fff;
    border: 1px solid $white-grey;
}

//Form number
.ant-input-number,
.ant-input-number-input {
    border-color: $white-grey !important;
    border-radius: 0 !important;
}

.ant-upload-drag-container:focus,
.ant-upload:focus,
.ant-upload-drag:focus,
.ant-form-item-control-input:focus {
    border-color: $main-color !important;
    border-right-width: 1px !important;
    outline: 0 !important;
    box-shadow: 0 0 0 2px rgba(87, 206, 156, 0.2) !important;
}

// Button
.ant-btn {
    padding: 3px 20px 4px 20px;
    font-weight: 500;
    font-size: 16px;

    &:hover,
    &:focus {
        color: $greenDark;
        border-color: $greenDark;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
        opacity: 0.5;
        color: $secondary-color;
        border-color: $secondary-color;
        background: transparent;
    }

    &.ant-btn-sm {
        font-size: 14px;
    }
}

.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
    padding-left: 14px;
    padding-right: 14px;
    border-left-color: $greenLight;
}

.ant-btn-primary {

    &:hover,
    &:focus {
        color: $secondary-color;
        border-color: $greenDark;
        background: $greenDark;
    }
    
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
        opacity: 0.5;
        color: $secondary-color;
        border-color: $main-color;
        background: $main-color;
    }
}

.ant-btn-ghost {
    border-color: $accent-color;
    color: $accent-color;
    
    &:hover {
        background: $accent-color;
        border-color: $accent-color;
        color: $white;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
        opacity: 0.5;
        color: $accent-color;
        border-color: $accent-color;
        background: transparent;
    }
}

.ant-btn-icon-only {
    vertical-align: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    
    & > svg {
        fill: $secondary-color !important;
        
        & * {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            fill: $secondary-color !important;
        }
        
    }

    &:hover > svg {
        fill: $greenDark !important;
        
        & * {
            fill: $greenDark !important;
        }
    }

    &[disabled],
    &[disabled]:hover > svg,
    &[disabled]:focus > svg,
    &[disabled]:active > svg {
        fill: $secondary-color;

        & * {
            fill: $secondary-color !important;
        }
    }
}

.ant-btn-text {
    padding: 0 10px;
    border-color: $secondary-color;
    border-radius: 0;
    color: $secondary-color;

    &:active {
        border-color: $secondary-color;
    }

    &:hover,
    &:focus {
        border-color: $accent-color;
        color: $accent-color;
        background: transparent;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
        
    }
}

.ant-btn-link {
    border-radius: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid $secondary-color;
    line-height: 1.2;
    height: 24px;

    &:active,
    &:hover,
    &:focus {
        border: none;
        border-bottom: 1px solid $greenDark;
        color: $greenDark;
        background: transparent;
    }

    &.negative {
        color: $white;
        border-bottom: 1px solid $white;

        &:active,
        &:hover,
        &:focus {
            border: none;
            border-bottom: 1px solid $greenDark;
            color: $greenDark;
            background: transparent;
        }
    }
}



// Tabs
.ant-tabs-bar {
    border-bottom: none !important;
}

// Steps
.ant-steps-item-title {
    font-family: Graphik;
    font-weight: 400;
    font-size: 16px;
}

//Checkbox
.ant-checkbox-wrapper {
    font-family: Graphik;
    font-weight: 400;
    font-size: 16px !important;
}

//Upload
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading,
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
    width: auto !important;
    display: inline-block !important;
    max-width: 100% !important;
    border-style: solid !important;
    border-radius: 0 !important;
}

.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
    padding-right: 45px !important;
}

.ant-upload-list-item-card-actions {
    right: 7px !important;
    top: 18px !important;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
    height: 28px !important;
    border-radius: 0;

    &:hover > span > svg {
        fill: $accent-color !important;
    }
}

.ant-upload-list-picture .ant-upload-list-item-progress {
    &:before {
        display: block;
        font-size: 10px;
        content: 'Loading...';
        width: 100%;
        height: 15px;
        align-items: flex-end;
        margin-bottom: -20px;
    }
}

.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 20px !important;
    width: 48px !important;
    margin-top: 0 !important;
    padding-left: 0 !important;
}

.ant-upload-list.ant-upload-list-picture {
    & > div > span {
        display: block;
    }

    .ant-upload-list-item {
        width: 100% !important;
    }
}

// Table
.ant-table-small .ant-table-thead > tr > th {
    background-color: $white-dark !important;
}

// Collapse
.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    > * {
    fill: $main-color;
}

// Tag
.ant-tag {
    font-size: 14px !important;
    padding: 1px 7px 3px !important;
}

// Display error message
.ant-form-item-has-feedback.ant-form-item-has-success
    .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning
    .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error
    .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating
    .ant-form-item-children-icon {
    top: auto !important;
    bottom: 11px !important;
}
