.container {
  display: block;
  position: relative;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  &:before {
    position: absolute;
    content: '';
    background: rgba(255,255,255, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
}