@import 'src/common/style/config';

.btn {
  margin: 20px 20px 0 0;
  display: block;
}

.content {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.box {
  margin-right: 20px;
  border-color: #fff;
  min-width: 290px;
}

.small {
  font-style: italic;
}

.row {
  padding: 40px 0;
  border-bottom: 1px solid $grey-light-1;
  
  &:last-child {
    padding-bottom: 0;
    border: none;
  }
}

.rowTitle {
  display: block;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
}