@import 'src/common/style/_config.scss';

.tag {
  display: inline-block;
  background: $secondary-color;
  color: $white;
  padding: 7px 12px 8px 12px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}