.container {
  padding: 50px 0;
}

.artworkContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-right: -25px;
}

.judgingHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}