@import "src/common/style/config";

.progress {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  text-align: right;
  padding-right: 5px;
  font-weight: 300;

  &:before {
    content: '';
    display: block;
    width: calc(100% + 5px);
    height: 5px;
    background: $main-color;
  }
}