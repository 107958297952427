﻿// Form Labels
.ant-checkbox-inner {
    border: 1px solid #979797 !important;
}


//notification
.ant-message {
    top: auto !important;
    bottom: 80px!important;
}

.custom-notification .ant-message-notice-content {
    padding: 7px 15px 8px !important;
    box-shadow: 0px 0px 24px rgba(55, 55, 55, 0.2) !important;
    background: #373737;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    
    .anticon {
        color: #fff;
    }
}