@import 'src/common/style/_config.scss';

$content-padding: 70px;

.container {
    background-color: $secondary-color;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
}

.content {
    width: 950px;
    min-height: 455px;
    background-color: $white;
    padding: $content-padding $content-padding 100px $content-padding;
}

.title {
    font-weight: 300 !important;
}

.subTitle {
    margin-bottom: 40px!important;
}

.messageBox {
    min-height: 50px;
}

.message {
    font-size: 16px !important;
    
    :global .anticon-check-circle {
        color: $main-color;
        font-size: 24px;
    }
}

.errorMsg {
    color: $error;
    font-size: 16px !important;
}

.backButton {
    padding: 0;
    border-radius: 0!important;
    
    & > span {
        border-bottom: 1px solid $main-color;
        display: inline;
    } 
}

.backBtnArrow {
    height: 15px;
    margin-bottom: -1px;
}