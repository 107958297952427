@import "src/common/style/config";

.answer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.answerPreview {
  .answerTitle {
    font-size: 17px!important;
    margin-bottom: 5px!important;
  }
}

.answerTitle {
  font-size: 16px!important;
  margin-bottom: 0!important;
}
.answerHelp {
  font-size: 14px;
  color: $grey-light;
}

.boxForm {
  background: $white-dark;
  border: 1px solid $white-grey;
  padding: 5px 10px;
  margin-bottom: 20px;
  margin-top: 5px;
  cursor: default;
  min-height: 42px;
}

.boxPreview {
  font-size: 14px;
  margin-bottom: 15px;
  
  :global .ant-typography {
    font-size: 16px !important;
    font-weight: 300!important;
  }
  
  :global .ant-table-cell {
    font-weight: 300!important;
    font-size: 16px;
  }
  
  a {
    font-weight: 300!important;
  }
}