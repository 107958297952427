@import 'src/common/style/_config.scss';

$content-padding: 70px;

.container {
    background-color: $secondary-color;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
}

.content {
    max-width: 950px;
    width: 100%;
    background-color: $white;
    padding: 50px $content-padding;
    position: relative;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.7);
    z-index: 2;
}

.title {
    font-weight: 300 !important;
    margin-bottom: 40px !important;
}

.messageBox {
    min-height: 70px;
}

.message {
    padding: 10px 0;
    font-size: 16px;
}

.messageError {
    color: $error;
}

.link {
    padding: 0 5px;
    white-space: nowrap;
}

.backButton {
    padding: 0;
    border-radius: 0!important;

    & > span {
        border-bottom: 1px solid $main-color;
        display: inline;
    }
}

.backBtnArrow {
    height: 15px;
    margin-bottom: -1px;
}
