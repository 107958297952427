@import "src/common/style/config";

.container {
  margin: 0;
  padding-bottom: 100px;
}

.header {
    font-size: 36px!important;
    font-weight: 300!important;
    text-transform: uppercase!important;
    letter-spacing: 1.2px!important;
    padding: 0 10px 5px 0!important;
    margin: 50px 0 60px 0!important;
  
}