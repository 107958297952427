@import 'src/common/style/config';

.textarea {
  min-height: 300px !important;
}

.maxLength {
  font-size: 12px;
  color: $greenDark;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}