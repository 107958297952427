@import "src/common/style/_config.scss";
@import "src/common/style/_mixins.scss";

.container {
  padding-bottom: 75px;
}

.title {
  @include submissionTitle;
}

.footer {
  @include submissionFooter;
  
}

.footerContent {
  @include submissionFooterContent;
}

.remove {
  margin-left: 20px;
}