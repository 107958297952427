@import 'src/common/style/config';

.wrapDialog {
    background: $black;
}

.dialog {
    height: 100%;
    background: $black;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center !important;
    overflow: hidden;

    :global .ant-modal-content {
        background: transparent !important;
        border-radius: 0;
        box-shadow: none;
        height: 100%;
    }

    :global .ant-modal-body {
        height: 100%;
        padding: 0 !important;
        text-align: center !important;
    }
}

.imageContainer {
    width: 100%;
    height: 100%;
}

.imageBox {
    width: 100vw;
    height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.image {
    display: inline-block !important;
    width: auto;
    max-width: 100%;
    max-height: 100vh;
}

.closeIcon {
    position: fixed;
    top: 40px;
    right: 40px;
    border: none;
    padding: 0;
    margin: 0;
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.icon {
    color: $white;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &:hover {
        color: $accent-color;
    }

    &:before,
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: currentColor;
        transform-origin: center center;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 0;
        transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}
.carousel {
    :global .slick-slide {
        overflow: hidden;
    }
    
    :global .slick-arrow {
        color: $white !important;
        display: block;
        width: 30px !important;
        height: 50px !important;
        background: rgba(0, 0, 0, 0.3) !important;
        z-index: 10;

        &:before,
        &:after {
            content: '' !important;
            display: block;
            width: 30px;
            height: 2px;
            background-color: currentColor;
            transform-origin: center center;
            position: absolute;
            top: 50%;
            margin-top: -1px;
            left: 0;
            transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }

    :global .slick-prev {
        left: 20px !important;

        &:before {
            transform: rotate(-45deg);
            top: 15px !important;
        }

        &:after {
            transform: rotate(45deg);
            top: 35px !important;
        }
    }
    :global .slick-next {
        right: 20px !important;

        &:before {
            transform: rotate(45deg);
            top: 15px !important;
        }

        &:after {
            transform: rotate(-45deg);
            top: 35px !important;
        }
    }
    
    :global .error-video-link {
        color: $white;
        
        a {
            color: $white;
            border-color: $white;
            
            &:hover {
                color: $focus-color;
                border-color: $focus-color;
            }
        }
    }
}
