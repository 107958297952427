@import "src/common/style/config";

.small {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
  display: block;
  color: $grey;
  margin-bottom: 8px;
  margin-top: -8px;
}

.dscExtend {
  display: block;
  margin-bottom: 8px;
  margin-top: -8px;
  
  & > *, 
  & a {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 300;
    color: $grey;
  }
}