﻿@font-face {
  font-family: "Graphik";
  src: url("../fonts/graphikweb-regular.woff2") format("woff2"),
      url("../fonts/graphikweb-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src:url("../fonts/graphikweb-light.woff2") format("woff2"),
  url("../fonts/graphikweb-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face{
  font-family:"Graphik";
  src:url("../fonts/graphikweb-medium.woff2") format("woff2"),
  url("../fonts/graphikweb-medium.woff") format("woff");
  font-weight:500;
  font-style:normal
}
@font-face{
  font-family:"Graphik";
  src:url("../fonts/graphikweb-bold.woff2") format("woff2"),
  url("../fonts/graphikweb-bold.woff") format("woff");
  font-weight:700;
  font-style:normal
}