@import "src/common/style/config";

@keyframes bounceArrow {
  from {
    transform: translate(0, 0)
  }
  to {
    transform: translate(15%, 0)
  }
}

@mixin background {
  cursor: pointer;
  font-weight: 300;
  font-size: 22px;
  background-color: $gry-light-2;
  border-top: 1px solid $gry-light-2;
  border-bottom: 1px solid $gry-light-2;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  min-height: 95px;
  
  &:hover {
    background-color: rgba(89,203,155,0.3);

    .collapseIcon {
      animation: bounceArrow;
      animation-duration: .25s;
      animation-direction: alternate;
      animation-iteration-count: infinite
    }
  }
}

.category {
  @include background;
}

.ul {
  margin: 0;
  padding: 0 0 30px 0;
}
.subcategory {
  @include background;
  background: #fff;
  border-top: 0;
  margin-bottom: 0;
  
  &:first-child {
    border-top: 1px solid $gry-light-2;
  }
}

.selected {
  background: rgba(89,203,155,0.3);
  border-color:  rgba(89,203,155,0.3);
}

.categoryDsc {
  padding: 20px 0;
  display: block;
  color: $grey-light;
  font-size: 16px;
}

.dsc {
  display: block;
  color: $grey-light;
  font-size: 16px;
  padding: 20px 0 20px 10px;
  flex: 1;
}

.title {
  font-size: 20px;
  flex: 1;
  padding-left: 10px;
}

//Collapse
.collapse {
  :global .ant-collapse-item > .ant-collapse-header  {
    @include background;
  }
  
  :global .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 35px;
    top: 35px;
    transform: rotate(90deg);
    transition: all 0.3s;
  }
  :global .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
    transform: rotate(-90deg);
  }
  
  :global .ant-collapse-content-box {
    padding: 0;
  }
}

.collapseWidth {
  width: 100%;
}

.collapseRow {
  width: 100%;
  max-width: calc(#{$max-width} + #{$page-padding});
  padding: 0 $page-padding;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  
}

.collapseBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.collapseTitle {
  flex: 1;
  font-weight: 300!important;
  font-size: 22px !important;
  margin: 0 !important;
}

.collapseDesc {
  flex: 1;
  padding: 10px 0;
}

.collapseIcon {
  margin-right: 30px;
}
