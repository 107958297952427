@import "src/common/style/config";

.preview {
  display: block;
  position: relative;
  cursor: pointer;
  
  .icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: block;
    background: #fff;
    border-radius: 50%;
  }
  
  .download {
    right: 50px;
    padding: 0 !important;
    height: 26px;
    width: 26px;
    box-shadow: inset 0px 0px 0px 1px $white-grey;
    line-height: 1;
    font-size: 15px;
    z-index: 10;
  }
  
  .zoom {
    padding: 0!important;
    width: 26px!important;
    height: 26px!important;
    border: none!important;
  }
  
  .previewImg {
    text-align: center;
    min-width: 80px;
  }

  .ratio {
    max-width: 100%;
    height: 0;
    display: block;
    background: rgba(0,0,0,0.03);
  }
}

.ratio {
 display: none;
}

.icon,
.download {
  display: none;
}

.img {
  display: inline-block;
  width: 100%;
}

.pdf {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}
.pdfName {
  padding-left: 5px;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pdfBox {
  width: 100%;
}

.play {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $grey-light-1;
  border: 1px solid $grey-light;
}

.playIcon {
  width: 60px;
  height: 60px;
}

.title {
  margin-left: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}