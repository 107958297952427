@import 'src/common/style/_config.scss';

.row {
  padding: 40px 0;
  border-bottom: 1px solid $grey-light-1;

  &:after {
    display: inline-block;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    margin-left: 6px;
  }

  &:last-child {
    padding-bottom: 0;
    border: none;
  }
}