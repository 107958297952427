﻿@import 'src/common/style/config';

$padding: 15px;

.collapse {
  width: 100%;
  background: none !important;
  border: none;
  border-bottom: 1px solid $main-color;
  border-radius: 0;
  padding: 0;
  :global .ant-collapse-header {
    border-bottom: 1px solid $main-color;
    border-radius: 0!important;
  }
}

.panel {
  :global .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
  }
}

.ArtworkBox {
  display: flex;
  width: 100%;
  flex-direction: row;
  border: 1px solid $gry-light-2;
  margin: 20px 0;
  align-items: stretch;
  justify-content: space-between;
}

.ArtworkCrop {
  position: relative;
  overflow: hidden;
  width: 145px;
  height: 95px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #000;
    opacity: 0;
    transition: all 0.3s;
    z-index: 1;
  }
  & > img,
  span {
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
  }
}

.ArtworkTitle {
  padding: 0 $padding;
  position: relative;
  flex: 1;
  align-self: center;
}

.ArtworkTitleLink {
  border-color: transparent;

  &:hover {
    border-bottom: 1.5px solid $main-color;
  }
}

.ArtworkPromote {
  align-self: center;
  margin: $padding;
}