@import "src/common/style/config";

.dragger > span {
  display: block;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
}

.text {
  font-size: 18px;
  padding: 20px 0 0;
}

.hint {
  font-size: 14px;
  font-weight: 300;
}

.item {
  position: relative;
  height: 66px;
  padding: 8px 85px 8px 8px;
  margin-top: 8px;
  border: 1px solid $bg-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.itemProgress {
  padding-right: 8px;
}

.image {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img {
  display: block;
  width: 48px;
}

.file {
  display: flex;
  flex: 1;
  max-width: 100%;
  
  :global .ant-upload-list-item {
    width: 100%;
    margin: 0!important;

    .ant-upload-span .ant-upload-list-item-card-actions-btn {
      opacity: 1!important;
    }
    
    &:hover {
      .ant-upload-list-item-info {
        background: transparent!important;
      }
    }
    
    .ant-upload-text-icon .anticon {
      font-size: 32px;
      padding: 0 8px;
    }
  }
}

.buttons {
  position: absolute;
  top: 17px;
  right: 3px;
}

.btn {
  width: 30px;
  height: 28px;
  border: 1px solid $secondary-color;
  margin: 0 5px;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  border-radius: 0;
  
  &:hover {
    border: 1px solid $accent-color;
    color: $accent-color;
  }
  
  &[disabled] {
    background: $grey-light-1;
    border-color: $grey-light-1;
    
    &:hover {
      background: $grey-light-1;
      border-color: $grey-light-1;
    }
  }
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.progressBar {
  flex: 1;
  min-width: 200px;
  padding-left: 20px;
}