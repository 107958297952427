@import 'src/common/style/config';
.center {
    text-align: center;
    font-weight: 300 !important;
    text-transform: uppercase;
}

.button {
    margin: 50px auto;
}

.steps {
    margin: 50px 0 60px 0;
}

.description {
    white-space: pre-wrap;
}
