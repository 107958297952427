@import 'src/common/style/_config.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.title {
  font-weight: 400;
  font-size: 16px;
}

.required {
  &:after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.small {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
  display: block;
  color: $grey;
  margin-bottom: 10px;
}

.roleContainer {
  margin: 5px;
  padding-top: 10px;
  border-top: 1px solid $gry-light-2;
}

.roleInput {
  background: $white!important;
}

.roleContent {
  width: 100%;
}

.roleLine {
  width: 100%;
  margin-top: -10px;
  overflow: hidden;
  
  &:first-child {
    margin-top: 0;
  }
}
.button {
  margin-left: 20px;
  margin-top: 35px;
  float: right;
  width: 40px;
  height: 40px;
}

.row {
  float: left;
  width: calc(100% - 40px);
}

.addButton {
  margin-top: 0;
}