@import 'src/common/style/_config.scss';

.title {
  font-weight: 400;
  font-size: 16px;
}

.required {
  &:after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}


.small {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
  display: block;
  color: $secondary-color;
  margin-bottom: 5px;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  
  :global .ant-space-item {
    width: 100%;
    min-width: calc(100% - 50px);
    
    &:last-child {
      min-width: 40px;
    }
  }
}

.addButton {
  margin-top: -10px;
}