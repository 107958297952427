@import 'src/common/style/_config.scss';

.root {
  padding: 10px 0;
}

.video {
  padding: 10px 0;
  border-bottom: 1px solid $grey-light;
  
  &:last-child {
    border-bottom: none;
  }
}

.play {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $white-semi-dark;
  border: 1px solid $white-semi-dark;
  color: $grey-light;

  &:hover {
    color: $focus-color;

    .playIcon {
      & * {
        fill: $focus-color;
      }
    }
  }
}

.playIcon {
  width: 60px;
  height: 60px;
  
  & * {
    fill: $grey-light;
  }
}

.error {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.error_link {
  display: inline-block;
  border-bottom: 1px solid $black;
  
  &:hover {
    color: $focus-color;
    border-color: $focus-color;
  }
}