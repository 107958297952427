@import "src/common/style/_config.scss";

.content {
  width: 315px;
  padding: 20px;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
}


.isDisabled {
  opacity: 0.5;
  position: relative;
  cursor: not-allowed;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}

.bordered {
  border: 1px solid $grey-light-1;
}

.selected {
  border-color: $main-color;
}

.radio {
  display: block;
  min-width: 24px;
  height: 24px;
  border: 1px solid $grey-light-1;
  border-radius: 50px;
  position: relative;
  margin-right: 20px;
  margin-top: 7px;
}

.radioSelected {
  border-color: $main-color;
  
  &:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background: $main-color;
  }
}

.txt {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.price {
  display: block;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.priceDsc {
  display: block;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  vertical-align: bottom;
}

.dsc {
  display: block;
  color: $grey-light;
  font-size: 12px;
  line-height: 18px;
}