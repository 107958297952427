@import "src/common/style/_config.scss";

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  :global .ant-empty-description {
    font-size: 16px;
    font-weight: 300;
    color: $grey-light;
  }
  
  :global .ant-empty-image {
    height: auto;
    margin: 0 5px 0 0;
  }
}

.icon {
  font-size: 32px;
  color: $grey-light-1;
}

.bottomM {
  margin-bottom: -20px;
}