@import 'src/common/style/config';

.appTitle {
  font-size: 32px!important;
  margin-bottom: 10px!important;
  margin-top: 0!important;
}

.appUserName {
  font-size: 18px;
  display: block;
}

.dsc {
  display: block;
  color: $grey-light;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
}