@import 'src/common/style/_config.scss';

.container {
  padding: 20px 0;
}

.content {
  width: 90%;
  max-width: 1000px;
}

.row {
  padding: 40px 0;
  border-bottom: 1px solid $grey-light-1;

  &:last-child {
    padding-bottom: 0;
    border: none;
  }
}

.rowTitle {
  display: block;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
}