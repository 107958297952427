@import "src/common/style/config";
$padding: 15px;

.judging {
  padding: $padding;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.judgeBtnGroup {
  margin: 0 -2px!important;

  :global .ant-radio-button-wrapper-checked {
    background: $secondary-color !important;
  }
}

.judgeBtn {
  font-size: 16px;
  border: 1px solid !important;
  border-radius: 100px !important;
  padding: 0 8px !important;
  line-height: 23px !important;
  height: 28px !important;
  margin: 0 2px !important;

  &:before {
    background-color: transparent !important;
  }
}


.judgeIcn {
  cursor: pointer;
  width: 32px;
  height: 32px;

  &:hover * {
    fill: $greenMiddle;
  }
}

.judgeIcnActive * {
  fill: $main-color !important;
}

.list {
  &.judging {
    flex-direction: row-reverse;
    min-width: 240px;
  }
  
  .judgeIcn {
    margin: 2px 2px 2px;
  }
}