@import 'src/common/style/config';

.container {
  padding: 20px 0 0;
}

.content {
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
}

.title {
  font-size: 22px!important;
  margin-bottom: 20px!important;
}

.steps {
  margin-bottom: 50px;
  margin-top: 30px;
  min-height: 90px;
  border-top: 1px solid $grey-super-light;
  border-bottom: 1px solid $grey-super-light;
  padding-top: 26px;
  
  :global  .ant-steps-item-content {
    width: calc(100% - 45px);
  }
  
  :global .ant-steps-item-container {
    width: 100%;
  }
  
  :global .ant-steps-item::after {
    display: none;
  }

  :global .ant-steps-item:first-child {
    text-align: left;
    padding-left: 20px !important;
  }

  :global .ant-steps-item:last-child .ant-steps-item-container{
    width: auto;
    min-width: 150px;
    margin-right: 55px;
  }

  :global .ant-steps-item-icon {
    margin-right: 12px;
  }

  :global .ant-steps-item:last-child {
      text-align: right;
  }
  
  :global .ant-steps-item-title {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
    
  }
  :global .ant-steps-item-process .ant-steps-item-title {
    font-weight: 500;
  }
  
  :global .ant-steps-item-description {
    margin-top: -10px;
    font-weight: 400;
    font-size: 14px;
    color: $main-color!important;
  }
}

.footer {
display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 80px;
  margin-bottom: 100px;
}

.btn {
  margin-left: 20px;
}

.formName {
  padding: 0 0 15px 0;
}

.formBox {
  padding: 25px 0 35px !important;
  width: 100%;
  border-top: 1px solid $grey-light-1;
  border-bottom: 1px solid $grey-light-1;


  :global .ant-form-item {
    padding: 0 0 35px!important;
    margin-bottom: 25px!important;
    border-bottom: 1px solid $grey-super-light;
    
    &:last-child {
      padding-bottom: 0!important;
      margin-bottom: 0!important;
      border: none!important;
    }
  }

  :global .ant-radio-inner {
    border-color: $secondary-color !important;
  }
}

.formTitle {
  font-size: 24px!important;
  margin-bottom: 5!important;
}

.formHelp {
  font-size: 14px;
  display: block;
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px;
}

.summaryTitle {
  font-size: 14px;
  line-height: 1.2;
}

.summaryPrice {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 7px;
}

.summaryInfo {
  font-size: 12px;
  line-height: 1.2;
  color: $grey-light;
}