@import 'src/common/style/config';

.infoRow {
  display: block;
  padding: 30px 0;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid $grey-super-light;

  &:last-child {
    border-bottom: none;
  }
}

.infoRowTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}