@import 'src/common/style/_config.scss';

.content {
    width: 100%;
    max-width: 850px;
    min-height: 200px;

    :global .ant-form-item {
        margin-bottom: 0 !important;
    }

    :global .ant-btn-add {
        margin-bottom: 20px !important;
    }
}

.checkboxField {
    :global .ant-form-item-control .ant-form-item-children-icon {
        right: 0 !important;
        bottom: -20px !important;
        width: 18px !important;
    }

    :global .ant-form-item-explain {
        padding-right: 28px !important;
    }
}

.multilineField {
    :global .ant-form-item-control .ant-form-item-children-icon {
        bottom: 29px !important;
    }
}

.help {
    margin: 20px 0;
}

.formContainer {
    padding: 40px 0;
    width: 100%;
}

.questionRow {
    margin-bottom: 15px;
}

.descSection {
    margin: 0 0 30px 0;
}

.descSection > * {
    margin: 30px 0 0 0;
}

.errorsInForm {
    font-size: 13px;
    color: $error;
    text-align: right;
    padding: 5px 0;
}

.loader {
    min-height: 200px;
}