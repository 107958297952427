﻿$main-color: #76F0BD;
$secondary-color: #373737;
$accent-color: #FF5C5C;

$green: #6FEAB6;
$error: #cc0000;

$greenDark: #34BF85;
$greenLight: #d8f4ed;
$greenMiddle: #bdead7;

$selected: rgba(89, 203, 155, 0.3);

$blue: #4E63F9;
$red: #FF5C5C;

$black: #000000;

$white: #FEFEFE;
$white-dark: #F5F5F5;
$white-semi-dark: #F8F8F8;
$white-grey: #C8C8C8;


$grey: #3F3F3F;
$grey-dark: #424241;
$grey-light: #979797;
$grey-light-1: #D1D1D1;
$gry-light-2: #E6E6E6;
$grey-super-light: #E9E9E9;

$base-font-size: 18px;

//Borders
$border-color-base: #e8e8e8;
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);

//Inputs
$focus-color: #59CB9B;


//Buttons
$btn-color: #59CB9B;
$btn-text: #3F3F3F;
$btn-font-size: 18px;

//Widths
$page-padding: 20px;
$max-width:1380px;
$admin-max-width: 1600px;

// ADMIN extra config
$border-color-base: #e8e8e8;
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
$bg-color: #ffffff;

$base-padding: 20px;
$base-margin: 20px;

$footer-height: 50px;
$header-height: 64px;
