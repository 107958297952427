@import 'src/common/style/config';

.submitted {
  color: $greenDark;
  font-size: 14px;
  font-weight: 500;
  min-width: 90px;
  
  & svg {
    width: 18px;
    height: 12px;
  }
  
  & * {
    fill: $greenDark!important;
    stroke: $greenDark!important;
  }
}