@import 'src/common/style/config';

.table {
  :global td.ant-table-cell {
    font-size: 16px!important;

    a {
      font-size: 18px;
      max-width: 300px;
    }
  }

  :global .ant-table-row-selected .ant-table-cell {
    background: rgba(204, 249, 230, 0.3)!important;
  }

  :global .ant-table-thead .ant-table-cell {
    background: none!important;
  }
  
  td {
    padding: 16px !important;
  }
  
  :global .ant-table-selection-column {
    padding-left: 0 !important;
  }
}

.title {
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
}

.image {
  margin: -1px;
  width: 180px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $white;
  border-radius: 10px;
  
  &.noImg {
    border: 1px solid $white-dark;
    border-radius: 10px;
  }
}

.icoImg {
  color: $gry-light-2;
  font-size: 32px;
}

.tag {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  border-radius: 50px;
  border: 1px solid $secondary-color;
  padding: 4px 10px 5px 10px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  text-transform: none !important;
  
  &.draft {
    background: $white;
    border-color: $white-grey;
    color: $black;
  }
}

.img {
  position: absolute;
  top:0;
  left: 0;
  width: 180px;
  height: 110px;
  background-size: cover;
  border-radius: 10px;
}

.titleLink {
  border-bottom: none;
  max-width: 400px !important;
  display: inline-block;
}

.borderCell {
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 50%;
    transform: translateY(-50%);
    background: $white-grey;
  }
}

.id {
  font-weight: 500;
}

.category {
  max-width: 250px;
}

.highlite {
  background-color: #EAFFF6;
  transition: all 0.3s ease-in;
}