@import 'src/common/style/config';

.radio {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 16px !important;
  margin-bottom: 15px;
}

.policyFieldRequired {
  &:after {
    display: inline-block;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.row {
  padding-bottom: 37px;
  margin-bottom: 30px;
  border-bottom: 1px solid $grey-super-light;
}