@import 'src/common/style/config';

.page {
  border-bottom: 20px solid $secondary-color;
  min-height: 100vh;
}


.container {
  padding: 50px 0 20px 0;
  position: relative;
}