@import 'src/common/style/config';

.container {
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
}

.btn {
  margin: 20px 20px 0 0;
  display: block;
}

.content {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.box {
  margin-right: 20px;
  border-color: #fff;
  min-width: 290px;
}

.small {
  font-style: italic;
}

.row {
  padding: 0 0 40px 0;
  border-bottom: 1px solid $grey-light-1;

  &:last-child {
    padding-bottom: 0;
    border: none;
  }
}

.rowTitle {
  display: block;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.formBox {
  padding: 15px 0 35px !important;
  width: 100%;
  border-bottom: 1px solid $grey-light-1;

  :global .ant-form-item {
    padding: 0 0 35px!important;
    margin-bottom: 25px!important;
    border-bottom: 1px solid $grey-super-light;

    &:last-child {
      padding-bottom: 0!important;
      margin-bottom: 0!important;
      border: none!important;
    }
  }

  :global .ant-radio-inner {
    border-color: $secondary-color !important;
  }
}

.footer {
  margin: 20px 0;
}

.backBtn {
  border: none!important;
  background: none!important;
  font-size: 16px!important;

  & > span {
    border-bottom: 0.5px solid;
  }
}

.pageTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $grey-super-light;
  margin: 30px 0;
  padding: 10px 0 5px;
}