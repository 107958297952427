@import "src/common/style/config";

@keyframes bounceArrow {
  from {
    transform: translate(0, 0)
  }
  to {
    transform: translate(15%, 0)
  }
}

.box {
  margin: 0;
  border-bottom: 1px solid $grey-light-1;
  position: relative;
  padding-top: 30px;
  padding-bottom: 20px;
  cursor: pointer;

  &:hover {
    background-color: rgba(89, 203, 155, 0.3);
    border-color: rgba(89, 203, 155, 0.3);
    .arrowIcon {
      color: $main-color!important;
      animation: bounceArrow;
      animation-duration: .25s;
      animation-direction: alternate;
      animation-iteration-count: infinite
    }
  }
  
  &:first-child {
    border-top: 1px solid $grey-light-1;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}

.icon {
  min-width: 90px;
  padding: 0 25px 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowIcon {
  width: 100%;
}

.stats {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.items {
  display: block;
  font-weight: 300;
}

.title {
  flex: 1;
  display: flex;
  align-items: center;
}