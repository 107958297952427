@import "src/common/style/config";

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.element {
  padding: 0 0 2px;
  border-radius: 0;
  line-height: 1;
  height: auto;
  margin: 5px 10px;
  
  &:hover {
    color: $main-color !important;
    border-bottom: 1px solid $main-color;
  }
}

.selected {
  color: $main-color !important;
  border-bottom: 1px solid $main-color;
}