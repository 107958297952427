.label {
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 6px;
  display: block;
  
  &:after {
    display: inline-block;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    margin-left: 6px;
  }
}