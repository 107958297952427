@import 'src/common/style/config';

$box-padding-top: 38px;

.box {
    position: relative;
    display: flex;
    padding: 20px;
    cursor: pointer;
}

.title {
    text-align: center;
    min-height: 120px;
}

.tiles {
    margin: 15px 15px 0 0;
    width: 23%;
    min-width: 300px;
    min-height: 210px;
    background: $white-dark;

    &:hover {
        border: 1px solid $focus-color;
    }
}

.tilesContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.listContent {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
}

.submission {
    margin: 0 0 10px 0;
}

.link {
    font-size: 16px;
    font-weight: 300;
    color: $secondary-color;
    line-height: 1.2;
    padding-bottom: 2px;
}

.list {
    margin: 0 0 20px;
    width: 100%;
    padding: 0 40px;
    min-height: 95px;
    background: $white-semi-dark;
    border: 1px solid $white-semi-dark;
    &:hover {
        border-color: $greenMiddle;
    }

    .submissionBox {
        flex-direction: row-reverse;
        margin: 0;
    }

    .submission {
        margin: 0 0 0 30px;
    }

    .link {
        font-size: 18px;
    }
    .title {
        text-align: left;
        min-height: 0;
    }
}
