@import 'src/common/style/_config.scss';

.btnAdd {
    margin-top: 30px;
}

.btn {
    margin-top: 10px;
    border: none !important;
    padding: 0 !important;

    & > span {
        text-decoration: underline !important;
    }

    &:focus,
    &:hover,
    &:active {
        background: transparent !important;
    }
}

.box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.partyBox {
    border: 1px solid $grey-light-1;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 1;
    max-width: 250px;
    min-width: 200px;
}

.partyBoxAddress {
    cursor: pointer;
}

.partyBoxSelected {
    border-color: $main-color;
    background-color: rgba(89, 203, 155, 0.3);
}

.party {
    display: flex;
    flex-direction: column;
}

.partyLine {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 3px;
}

.accordion {
    :global .ant-collapse-header {
        padding-left: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: 12px !important;
        border-bottom: 1px solid $white-dark !important;
    }
    :global .ant-collapse-content-box {
        padding: 0 !important;
    }
}

.dropdown {
    min-width: 550px;
    max-width: 100%;
    border-color: $white-grey !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &:hover,
    &:active,
    &:focus {
        border-color: $main-color !important;
        background-color: transparent !important;
        color: $secondary-color !important;
    }

    :global .anticon-down {
        color: $main-color;
    }
}

.dropdownMenu {
    &:global.ant-menu-light {
        background: $white;
        border: 1px solid $white-grey;
    }
}

.dropdownItem {
    display: block;
    margin: 0;

    &:global.ant-menu-item-selected,
    &:global.ant-menu-item:active,
    &:global.ant-menu-submenu-title:active {
        color: $white-grey !important;
        background: transparent !important;
    }
}

.dropdownEmpty {
    &:global.ant-menu-item {
        color: $white-grey;
    }
}

.dropdownText {
    font-size: 16px;
}

.dropdownLink {
    font-size: 12px !important;
    margin-right: 10px;
    position: relative;
    z-index: 2;
}

.dropdownIcon {
    color: #57ce9c;
    margin: 0 !important;
    font-size: 14px !important;
}

.truncateBtn {
    max-width: 100%;
}

.partyTruncate {
    display: block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}