@import "src/common/style/_config.scss";

.content {
  background: $secondary-color;
}

.block {
  width: 100%;
  height: 76px;
  position: relative;
  overflow: hidden;
}

.logoBox {
  position: absolute;
  top:20px;
  left: 0;
}

.breadcrumbs {
  overflow: hidden;
  margin: 22px 170px 0 40px;
}

.buttonsBox {
  position: absolute;
  right: 0;
  top: 15px;
}

.logo {
  width: 40px;
  height: 40px;
  cursor: pointer;
  
  & * {
    fill: #fff !important;
    mask: none;

  }
} 

.button {
  flex: 1;
  font-size: 14px !important;
  margin-left: 26px;
  margin-top: 10px;
}