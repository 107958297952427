@import "src/common/style/config";

.list {
  margin: 0 0 0 20px;
  padding: 0;
  color: $white;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.listLi {
  margin: 0;
  padding: 0;
}

.el {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 1.15;
  margin-right: 8px;
  color: $white;
  
  &:before {
    content: ' ';
    display: inline-block;
    height: 1px;
    width: 40px;
    position: relative;
    top: -4px;
    background: currentColor;
    margin-right: 10px;
  }
  @media only screen and (max-width: 1024px) {
    &:before {
      width: 20px;
    }
  }
}

.elLink {
  cursor: pointer;
  &:hover {
    color: $accent-color;
  }
}