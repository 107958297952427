@import "src/common/style/_config.scss";

.container {
  display: inline-block;
}

.link {
  color: $secondary-color;
  font-size: $base-font-size;
  line-height: 1.2;
  padding-bottom: 2px;
  border-bottom: 1.5px solid$main-color;
  //box-shadow: $main-color 0px -1.5px 0px inset;
}

.linkNegative {
  color: $white;
}

.plain {
  color: $blue;
  box-shadow: currentColor 0px -1.5px 0px inset;
}

.huge {
  font-size: calc(#{$base-font-size} + 22px);
}

.large {
  font-size: calc(#{$base-font-size} + 6px);
}

.small {
  font-size: calc(#{$base-font-size} - 2px);
}

.icon {
  width: 10px;
  height: 20px;
  margin: 0 4px -6px 4px;
}
