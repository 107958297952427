@import 'src/common/style/_config.scss';

@keyframes bounceArrow {
    from {
        transform: translate(0, 0)
    }
    to {
        transform: translate(15%, 0)
    }
}

.ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.row {
    border-bottom:1px solid $gry-light-2;
    cursor: pointer;
    transition: all 0.3s ease-in;
    
    &:hover {
        background-color: rgba(89, 203, 155, 0.3);
        border-color: rgba(89, 203, 155, 0.3);
        .arrowIcon {
            color: $main-color!important;
            animation: bounceArrow;
            animation-duration: .25s;
            animation-direction: alternate;
            animation-iteration-count: infinite
        }
    }
}

.rowTitle {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    padding: 8px 0;
    background-color: $main-color;
    color: $white;
}

.rowTitlePast {
    background-color: transparent;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAM0lEQVQoU2N8/Pjxf1lZWUYGHAAsgU8RXCcuRShGY1OEYTe6IqyOQ1aE0/UwRTgVwHwHAHv2H7E3MtxPAAAAAElFTkSuQmCC);
    color: $secondary-color;
    border:1px solid $gry-light-2;
}

.rowTitleFuture {
    background-color: $secondary-color;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 90px;
}
.icon {
    min-width: 90px;
    padding: 0 25px 0 15px;
    display: flex;
    justify-content: center;
}

.arrowIcon {
    width: 100%;
}
.title {
    font-size: 18px;
    flex: 1;
    padding: 20px 20px 20px 0;
}
.dsc {
    display: block;
    font-style: italic;
    color: $grey-light;
    font-size: 14px;
    padding: 20px 10px;
    flex: 1;
}

.date {
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 0 20px;
    text-align: right;
}

.empty {
    padding: 20px;
    border:1px solid $gry-light-2;
    border-top: none;
}