@import 'src/common/style/config';

.container {
    padding: 50px 0;
}

.title {
    max-width: 75%;
}

.navBtn {
    border: none !important;
    padding: 0 !important;
    font-weight: 500 !important;
    border-bottom: 1px solid !important;
    height: 28px !important;
}

.navPrevBtn {
    margin-right: 30px;
}

.image {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 1px solid #f5f5f5;
    > img {
        width: 100%;
    }
}

.sidebarContainer {
    position: sticky;
    top: 0;
    background: $gry-light-2;
    padding: 20px;

    :global .ant-collapse,
    :global .ant-collapse-content,
    :global .ant-collapse-item {
        border: none;
        border-radius: 0 !important;
        background: transparent;
    }

    :global .ant-collapse-header {
        border-radius: 0 !important;
        padding: 0 35px 5px 0 !important;
        border-bottom: 1px solid $main-color !important;
    }

    :global .ant-collapse-content-box {
        padding: 10px 0 !important;
        border-radius: 0 !important;
        border: none !important;
    }
}

.sidebarTitle {
    & + div {
        padding: 0;
        margin-bottom: 20px;
        justify-content: flex-end;
    }
}

.answerBox {
    :global .ant-collapse-header {
        padding-left: 0 !important;
        border-radius: 0 !important;
        border-bottom: 1px solid $grey-light-1;
    }

    :global .ant-collapse-content-box {
        padding: 15px 0 !important;
    }
}

.id {
    font-size: 16px;
    font-weight: 300;
}

.topBorder {
    padding-top: 20px;
    border-top: 1px solid $grey-super-light;
}