.container {
  padding: 50px 0;
}

.submissions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

.submissionsTiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -20px;
}