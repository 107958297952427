﻿@import 'src/common/style/config';

.dialog {
  top: 20px;
  
  :global .ant-modal-body {
    padding-top: 15px;
  }
  
  :global .ant-input-search-button {
    border-radius: 0;
  }
}