@import "src/common/style/config";

.container {
  padding: 20px 0 100px 0;
  min-height: 85vh;
}

.title {
  margin-bottom: 20px !important;
}

.dsc {
  margin-bottom: 40px!important;
  color: $grey-light;
}

.links {
  margin: 0 10px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: #fff;
  border-top: 1px solid $grey-super-light;
}