@import 'src/common/style/config';

.dragger > span {
    display: block;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;
}

.text {
    font-size: 18px;
    padding: 20px 0 0;
}

.link {
    cursor: pointer;
    box-shadow: $main-color 0px -1.5px 0px inset;
}

.hint {
    font-size: 14px;
    font-weight: 300;
}
