@import 'src/common/style/config';

.content {
  padding: 30px 0;
  margin-bottom: 20px;
}

.pageTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $grey-super-light;
  margin: 30px 0;
  padding: 10px 0 5px;
}

.membershipDisabled {
  color: #f5675d
}

.empty {
  margin: 40px auto;
}

.box {
  padding-top: 40px;
}

.dataContent {
  width: 100%;
  max-width: 690px;
  margin: 0 auto 60px;
}

.partyRow {
  display: block;
}

.rowTxt {
  display: block;
  font-size: 16px;
}

.fileRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.fileBtn {
  background: transparent!important;
  border-color: transparent!important;
  color: $secondary-color!important;
  
  &:hover {
    color: $main-color!important;
    border-color: $main-color!important;
  }
  
  :global .anticon-download {
    margin: 0;
  }
}

.addCard {
  margin: 20px 0;
  
  &:hover .addCardTxt {
    color: #fff;
  }
}

.addCardTxt {
  color: $accent-color;
  
}

.btn {
  border: none!important;
  background: none!important;
  font-size: 16px!important;
  padding: 0;
  height: 30px;

  & > span {
    border-bottom: 0.5px solid;
  }
}


//Title
.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $secondary-color;
  padding-bottom: 5px;
}

.titleTxt {
  display: block;
  font-size: 24px;
  font-weight: 400;
}

.titleIcon {
  display: block;
  color: $secondary-color;
  border: none!important;
  background: transparent!important;
  
  :global .anticon {
    cursor: pointer;
    font-size: 16px !important;
  }
  
  &:hover {
    color: $main-color;
  }
}

.titleLink {
  display: block;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: $grey-light;
  padding: 0!important;
  height: 24px;

  &:hover {
    color: $main-color;
  }
}

.subscriptionTileLink {
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: $grey-light;
  padding: 0!important;
  height: 24px;

  &:hover {
    color: $main-color;
  }
}

.btnLink {
  display: block;
  font-size: 14px;
  cursor: pointer;
  color: $grey-light;
  padding: 0!important;
  border-radius: 0;
  border-bottom: 1px solid $grey-light;
  margin: 15px 0 5px;
  height: auto;

  &:hover {
    color: $main-color;
    border-bottom-color: $main-color;
  }
}

.personalData {
  margin: 20px 0;
  
  :global .ant-row {
    padding: 20px 0;
    border-bottom: 1px solid $grey-super-light;
    
    .ant-row {
      border: none;
      padding: 0;
    }
  }
  
  :global .ant-radio-inner {
    border-color: $secondary-color;
  }
}

.addUrlField {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid $grey-super-light;

  :global .ant-form-item {
    padding: 0!important;
    border: none!important;
    margin-bottom: 25px!important;
  }

  :global .ant-form-item:last-child {
    margin-bottom: 30px!important;
  }
}

.renewalAlert {
  margin-top: 30px !important;
  font-size: 14px!important;
}

.partyStyle {
  & > span {
    font-weight: 400;
  }
}

.futureMembership {
  margin-top: 40px;
}