@import 'src/common/style/config';

.required::after {
    display: inline-block;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    width: 10px;
}

.small {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 300;
    display: block;
    color: $grey;
    margin: 0 0 8px 24px;
}