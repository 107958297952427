@import 'src/common/style/config';

$height: 48px;

.container {
  height: $height;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  height: $height;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item {
  line-height: 30px;
  border-bottom: 2px solid transparent;
  margin: 0 20px;
  cursor: pointer;
  transition: border-bottom-color 300ms ease-out;
  
  &:first-child {
    margin-left: 0;
  }
  
  &:hover {
    border-bottom-color: $main-color;
    
  }
}

.special {
  color: $main-color;
}

.itemActive {
  border-bottom-color: $main-color;
}