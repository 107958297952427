@import 'src/common/style/_config.scss';

$content-padding: 70px;

.container {
    background-color: $secondary-color;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
}

.content {
    width: 550px;
    background-color: $white;
    padding: $content-padding $content-padding 100px $content-padding;
}

.title {
    font-weight: 300 !important;
    margin-bottom: 40px !important;
}

.messageBox {
    min-height: 60px;
}

.errorMsg {
    color: $error;
    font-size: 16px !important;
}