@import 'src/common/style/config';

$tileWidth: 320px;
$tileHeight: 212px;

$listWidth: 145px;
$listHeight: 95px;

$padding: 15px;

.box {
    display: flex;
    width: 100%;
    flex-direction: row;
    border: 1px solid $gry-light-2;
    margin: 0 0 20px 0;
    background-color: $white-dark;
    
}

.crop {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: $gry-light-2;
    
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background: #000;
        opacity: 0;
        transition: all 0.3s;
        z-index: 1;
    }
    & > img,
    span {
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
    }
}

.title {
    padding: $padding $padding 0;
    position: relative;
    flex: 1;
}

.titleBox {
    cursor: pointer;
    line-height: 1.4;
    
    &:hover {
        .titleLink {
            border-bottom: 1.5px solid $main-color;
        }
    }
}

.titleLink {
    font-size: 18px;
    padding-bottom: 2px;
    border-color: transparent;
}

.notes {
    color: $secondary-color;
    font-size: 16px;
    position: absolute;
    width: $tileWidth;
    height: $tileHeight;
    top: -$tileHeight;
    left: 0;
    padding: $padding;
    z-index: 3;
    background: rgba(87, 206, 156, 0.9);
}

.tiles {
    width: 23%;
    max-width: $tileWidth;;
    min-width: 260px;
    flex-direction: column;
    margin: 0 25px 25px 0;
    & > .crop {
        width: 100%;
        height: $tileHeight;
    }
}

.list {
    justify-content: space-between;
    align-items: stretch;
    margin: 0 0 20px 0;

    & > .title {
        flex: 1;
        padding: $padding;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & > .crop {
        width: $listWidth;
        height: $listHeight;
    }

    h4 {
        margin: 0 !important;
    }

    .notes {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $main-color;
    }
}

.tag {
    display: block;
    color: $grey-dark;
    font-size: 12px;
    font-weight: 200;
    align-self: center;
}
