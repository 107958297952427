@import 'src/common/style/_config.scss';

@keyframes bounceArrow {
  from {
    transform: translate(0, 0)
  }
  to {
    transform: translate(15%, 0)
  }
}

.panel {
  padding: 40px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid $accent-color;
  border-bottom: 1px solid $accent-color;
  cursor: pointer;
  transition: all 0.3s ease-in;

  @media (max-width: 767px) {
    padding: 20px;
  }
  
  &:hover {
    background: $accent-color;
    border-top: 1px solid $accent-color;
    border-bottom: 1px solid $accent-color;
    
    .title,
    .desc {
      color: #fff;
    }
    
    .arrowIcon {
      color: #fff!important;
      animation: bounceArrow;
      animation-duration: .25s;
      animation-direction: alternate;
      animation-iteration-count: infinite
    }
  }
}

.arrow {
  min-width: 75px;
}

.arrowIcon {
  color: $accent-color!important;
}

.contentEl {
  display: block;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  
  @media (max-width: 480px) {
   flex-direction: column;
  }
}

.title {
  display: block;
  width: 50%;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: $accent-color;
  padding-right: 10px;

  @media (max-width: 767px) {
    padding: 0;
    width: 100%;
  }
}

.desc {
  display: block;
  width: 50%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $accent-color;
  
  @media (max-width: 767px) {
    width: 100%;
  }
}