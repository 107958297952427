@import 'src/common/style/config';

.container {
  padding: 50px 0 0;
}

.intro {
  width: 100%;
  max-width: 900px;
}

.submissionBox {
  overflow: hidden;
  padding-bottom: 50px;
}


.payBtn {
  margin: 0 0 0 20px;
  text-transform: uppercase;
} 

.helpText {
  display: block;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.addButton {
  margin-top: 50px;
  
}

.actionButtons {
  position: relative;
  padding-left: 20px;
  min-width: 225px;
  
  :global .ant-btn {
    margin-left: 15px;
  } 
}

.status {
  position: absolute;
  top: -35px;
  right: 0;
  background: #FEDDDD;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  padding: 0 8px;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  & > svg {
    padding-right: 5px;
  }
}

.empty {
  margin-top: 50px;
}

.tabList {
  & :global .ant-tabs-nav:before {
    border: none;
  }
}