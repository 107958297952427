@import "src/common/style/_config.scss";

$login-padding: 50px;

.container {
  background-color: $secondary-color;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
}

.content {
  width: 950px;
  background-color: $white;
}
