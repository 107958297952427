@import 'src/common/style/_config.scss';

.dropdown {
    min-width: 550px;
    border-color: $white-grey !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &:hover,
    &:active,
    &:focus {
        border-color: $main-color !important;
        background-color: transparent !important;
        color: $secondary-color !important;
    }

    :global .anticon-down {
        color: $main-color;
    }
}

.selected {
    flex: 1;
}

.clear {
    opacity: 0.5;
    display: block;
    cursor: pointer;
    margin: 0 8px;
    
    &:hover {
        opacity: 1;
    }
}