@import "src/common/style/_config.scss";

$login-padding: 70px;

.container {
  padding: $login-padding $login-padding 90px $login-padding;
  height: 100%;
  position: relative;
}

.or {
  position: absolute;
  bottom: -31px;
  right: 50%;
  
  width: 60px;
  height: 60px;
  border: 1px solid #e9e9e9;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  color: #989898;
  line-height: 54px;
  z-index: 1;
  background: #fff;
  font-size: 16px;
  letter-spacing: -1px;
  
  @media screen and (min-width:768px){
    top: 38%;
    right: -31px;
  }
  
}

.bg {
  background-color: #E9E9E9;
}

.title {
  font-weight: 300!important;
  margin-bottom: 40px !important;
}

.btn {
  min-width: 160px;
}

.button {
  margin:  0 0 30px 0;
  background: $secondary-color;
  border-color: $secondary-color;
  
  &:last-child {
    margin: 0;
  }
}

.reason { 
  display: block;
  min-height: 180px;
  font-weight: 300;
  font-size: 18px;
  line-height: 35px;
  padding: 30px 0;
  margin-bottom: 28px;
}

.createBtn {
  background: $secondary-color;
  border-color: $secondary-color;
  color: $white;
  min-width: 200px;
  margin-left: 30px;
  padding-top: 2px!important;
  
  &:hover {
    background: $accent-color;
    border-color: $accent-color;
    color: $white;
  }
}
/*
.facebook:hover {
  background-color: #4267B2 !important;
  border-color: #4267B2 !important;
}

.twitter:hover {
  background-color: #1DA1F2 !important;
  border-color: #1DA1F2 !important;
}

.google:hover {
  background-color: #DB4437 !important;
  border-color: #DB4437 !important;
}
*/

.link {
  margin-top: 10px;
}

.links {
  margin-top: 20px;
  width: 100%;
}

.icon {
  width: 18px;
  height: 16px;
  margin-right: 5px;
}

.messageBox {
  min-height: 40px;
}

.message {
  color: $error;
  font-size: 16px !important;
}