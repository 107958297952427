@import 'src/common/style/config';

.spinnerStyle {
  margin-left: 2px;
}

.table table {
  font-size: 14px;
}

.tooltip {
  font-size: 12px;
}