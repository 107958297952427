@import "src/common/style/config";

.header {
  letter-spacing: -1px!important;
  line-height: 1.2!important;
  margin-bottom: 40px!important;
  margin-top: 20px!important;
  max-width: 1000px;
  text-transform: uppercase;
  font-weight: 300!important;
}

.xsmall {
  font-size: 20px !important;
}

.small {
  font-size: 30px!important;
}

.normal {
  font-size: 40px!important;
}

.big {
  font-size: 45px!important;
}

.onlyBottom {
  margin-top: 0!important;
  margin-bottom: 15px!important;
}

.marginSmall {
  margin-bottom: 5px!important;
}

.marginMiddle {
  margin-bottom: 20px!important;
}

.marginBig {
  margin-bottom: 50px!important;
}