@import "src/common/style/config";

.image {
  display: flex;
  justify-content: flex-start;
  margin: 10px auto;
  width: 100%;
}

.imgFormContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border: 1px solid $white;
  width: 100%;
  margin-bottom: 10px;
}

.title {
  margin-left: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}