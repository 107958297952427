@import 'src/common/style/config';

.checkbox {
    display: block;
    height: 30px;
    line-height: 30px;
    margin: 0 !important;

    :global .ant-checkbox-inner {
        display: inline-block !important;
    }
}

.addUrlField {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid $grey-super-light;

    :global .ant-form-item {
        padding: 0 !important;
        border: none !important;
        margin-bottom: 25px !important;
    }

    :global .ant-form-item:last-child {
        margin-bottom: 30px !important;
    }

    //:global .ant-btn-text {
    //  margin-top: 20px!important;
    //}

    :global .ant-form-item-explain {
        color: $error !important;
        position: absolute;
        bottom: 180%;
        white-space: nowrap;
    }
}

.addUrlField > div > button {
    margin-top: 20px !important;
}

.noBorderRow {
    border-bottom: 0 none $grey-super-light !important;
}
