@import 'src/common/style/_config.scss';

.content {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  width: 520px;
}

.box {
  margin-right: 20px;
}
